import config from '../config.js';
import axios from 'axios';

const BASE_URL = config.BASE_URL;

export const loginAPI = async (emailOrUsername, password) => {
    let loginAPIUrl = `${BASE_URL}/auth/login`;
    try {
        const response = await axios.post(loginAPIUrl, {
          emailOrUsername,
          password,
        });

        return response.data;
    } catch (error) {
        const errorMessage = error.response ? error.response.data.error : error.message;
        console.log('loginAPI.errorMessage', errorMessage);
        throw new Error(errorMessage);
    }
};

export const validateAuthTokenAPI = async (token) => {
    let apiUrl = `${BASE_URL}/auth/validate`;
    try {
        const response = await axios.post(apiUrl, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        const errorMessage = error.response ? error.response.data.error : error.message;
        console.log('errorMessage', errorMessage)
        throw new Error(errorMessage);
    }
};