import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './pages/layout/Layout';
import Login from './pages/login/Login';
import Subscriptions from './pages/subscriptions/Subscriptions';
import Children from './pages/children/Children';
import { AuthProvider } from './contexts/AuthContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import ChildSubscriptions from './pages/childsubscriptions/ChildSubscriptions';
import NotFound from './pages/notfound/NotFound';

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Router>
          <Layout>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Subscriptions />} />
              <Route path="/children" element={<Children />} />
              <Route path="/child/subscriptions" element={<ChildSubscriptions childUserId='' />} />
              <Route path="*" element={<NotFound />} /> {/* Add the catch-all route */}
            </Routes>
          </Layout>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;