import React, { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import './Login.css';
import { useAuth } from '../../contexts/AuthContext';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { loginAPI, validateAuthTokenAPI } from '../../services/AuthService';
import { deleteFromLocalStorage, storeToken, storeUserData, storeUserId } from '../../services/ls';
import { Divider } from 'primereact/divider';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login, user, logout } = useAuth();
  const navigate = useNavigate();
  const msgs = useRef<Messages>(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenFromUrl = queryParams.get('token');

  const loginWithToken = async () => {
    try {
      const response = await validateAuthTokenAPI(tokenFromUrl);
      if (response && response.token && response.userData) {
        if (response.userData?.role !== 'student' && response.userData?.role !== 'parent') {
          return;
        }
        await storeToken(response.token);
        await storeUserId(response.userData.id);
        await storeUserData(JSON.stringify(response.userData));
        login(response.userData);
        let ud = response.userData;

        if (ud?.role === 'student') {
          navigate('/');
          return;
        }
        if (ud?.role === 'parent') {
          navigate('/children');
          return;
        }

      }
    } catch (error) {
      console.error('validateAuthTokenAPI.error', error);
      msgs.current?.show({
        severity: 'error',
        summary: 'Einloggen fehlgeschlagen',
        detail: 'Username, Email oder Passwort ist nicht korrekt!',
      });
    }
  }

  if (user) {
    // If token in URL and logged in, logout user then login with new token
    if (tokenFromUrl) {
      deleteFromLocalStorage('userToken');
      deleteFromLocalStorage('userId');
      deleteFromLocalStorage('userData');
      logout();

      // Login with new token
      loginWithToken();
    }
    return <Navigate to="/" />;
  }

  // Handle login with token from query param
  if (tokenFromUrl) {
    loginWithToken();
  }

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!username || !password) {
      return;
    }
    try {
      const response = await loginAPI(username, password);
      if (response && response.token && response.userData) {
        if (response.userData?.role !== 'student' && response.userData?.role !== 'parent') {
          return;
        }
        await storeToken(response.token);
        await storeUserId(response.userData.id);
        await storeUserData(JSON.stringify(response.userData));
        login(response.userData);
        let ud = response.userData;

        if (ud?.role === 'student') {
          navigate('/');
          return;
        }
        if (ud?.role === 'parent') {
          navigate('/children');
          return;
        }

      }
    } catch (error) {
      console.error('loginAPI.error', error);
      msgs.current?.show({
        severity: 'error',
        summary: 'Einloggen fehlgeschlagen',
        detail: 'Username, Email oder Passwort ist nicht korrekt!',
      });
    }
  };

  return (
    <div className="login-container">
      <h2>Verwalten Sie Ihre Lern Buddy Abonnements</h2>
      <Divider />
      <h2>Schüler & Eltern - Einloggen</h2>
      <form onSubmit={handleLogin}>
        <div className="login-form">
          <InputText
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username/Email"
          />
          <InputText
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Passwort"
          />
          <Button label="Einloggen" type="submit" className="p-button-primary" />
          <small>Für Hilfe schreiben Sie bitte eine E-Mail an info@lern-buddy.com</small>
          <Messages ref={msgs} />
        </div>
      </form>
    </div>
  );
};

export default Login;
