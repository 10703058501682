import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './Children.css';
import { Button } from 'primereact/button';
import { useQuery } from 'react-query';
import { retrieveToken, retrieveUserId } from '../../services/ls';
import { useAuth } from '../../contexts/AuthContext';
import { Navigate, useNavigate } from 'react-router-dom';
import { parentChildrenAPI } from '../../services/parent';

const Children: React.FC = () => {
  const { user } = useAuth();
  const [token, setToken] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTokenAndUserId = async () => {
      const fetchedToken = await retrieveToken();
      const fetchedUserId = await retrieveUserId();
      setToken(fetchedToken);
      setUserId(fetchedUserId);
    };
    fetchTokenAndUserId();
  }, []);
  
  const { data } = useQuery(
    ['children', token, userId],
    () => parentChildrenAPI(token as string),
    {
      enabled: !!token && !!userId,
      staleTime: Infinity,
    }
  );

  if (!user || user?.role !== 'parent') {
    return <Navigate to="/login" />;
  }

  const goToChildSubscriptions = (childId: number) => {
    navigate('/child/subscriptions', { state: { childUserId: childId } });
  }

  const subscriptionBodyTemplate = (rowData: { id: number }) => {
    return <Button onClick={() => goToChildSubscriptions(rowData.id)} label="Abonnemente" icon="pi pi-pen-to-square" className="p-button-primary" />;
  };

  return (
    <div className="children-container">
      <DataTable value={data} rows={100}>
        <Column field="firstName" header="Vorname" />
        <Column field="lastName" header="Nachname" />
        <Column header="Abonnemente" body={subscriptionBodyTemplate} />
      </DataTable>
      <div className='mt-3 text-center'>
        <small>Für Hilfe schreiben Sie bitte eine E-Mail an info@lern-buddy.com</small>
      </div>
    </div>
  );
};

export default Children;
