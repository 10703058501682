import config from '../config.js';
import axios from 'axios';

const BASE_URL = config.BASE_URL;

export const parentChildrenAPI = async (token) => {
    let apiIUrl = `${BASE_URL}/users/find`;
    try {
        const response = await axios.get(apiIUrl, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data.users;
    } catch (error) {
        const errorMessage = error.response ? error.response.data.error : error.message;
        console.log('errorMessage', errorMessage)
        throw new Error(errorMessage);
    }
};

export const parentChildByIdAPI = async (token,id) => {
    let apiIUrl = `${BASE_URL}/users/find`;
    try {
        const response = await axios.get(apiIUrl, {
            headers: {  
                'Authorization': `Bearer ${token}`
            }
        });
        
        return response.data.users.filter(item => item.id === id);
    } catch (error) {
        const errorMessage = error.response ? error.response.data.error : error.message;
        console.log('errorMessage', errorMessage)
        throw new Error(errorMessage);
    }
};