import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Divider } from 'primereact/divider';
import { Fieldset } from 'primereact/fieldset';
import { Messages } from 'primereact/messages';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Navigate } from 'react-router-dom';
import PayPalModal from '../../components/PaypalCheckout';
import { useAuth } from '../../contexts/AuthContext';
import { retrieveToken, retrieveUserId } from '../../services/ls';
import { cancelStudentSubscription, payPalCallbackAPI, pendingOrActiveLastSubscriptionsByUserIdAPI } from '../../services/subscriptions';
import './Subscriptions.css';

const Subscriptions: React.FC = () => {
  const { user } = useAuth();
  const [token, setToken] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const toast = useRef<Toast>(null);
  const msgs = useRef<Messages>(null);
  const [lastMessage, setLastMessage] = useState<string | null>(null);
  const [visibleCancelDialog, setVisibleCancelDialog] = useState<boolean>(false);
  // const [paymentSuccess, setPaymentSuccess] = useState<boolean | null>(null);
  // const [paymentError, setPaymentError] = useState<string | null>(null);
  const [paypalCheckoutStandardModalVisible, setPaypalCheckoutStandardModalVisible] = useState(false);
  const [paypalCheckoutBasicVisible, setPaypalCheckoutBasicModalVisible] = useState(false);
  const [paypalCheckoutProVisible, setPaypalCheckoutProModalVisible] = useState(false);
  const [paypalCheckoutUnlimitedVisible, setPaypalCheckoutUnlimitedModalVisible] = useState(false);

  useEffect(() => {
    // msgs.current?.clear();
    queryClient.invalidateQueries(['myLastSub', token, userId]);
    const fetchTokenAndUserId = async () => {
      const fetchedToken = await retrieveToken();
      const fetchedUserId = await retrieveUserId();
      setToken(fetchedToken);
      setUserId(fetchedUserId);
    };
    fetchTokenAndUserId();
  }, [queryClient, token, userId]);

  const { data } = useQuery(
    ['myLastSub', token, userId],
    () => pendingOrActiveLastSubscriptionsByUserIdAPI(token as string, userId as string),
    {
      enabled: !!token && !!userId,
      staleTime: Infinity,
    }
  );

  const hasActiveSubscription = data;

  const acceptCancel = async () => {
    toast.current?.show({ severity: 'success', summary: 'Confirmed', detail: 'Das Abonnement wurde gekündigt', life: 4000 });
    try {
      await cancelStudentSubscription(token, data?.id);
      queryClient.invalidateQueries(['myLastSub', token, userId]);
      msgs.current?.clear();
    } catch (error) {
      console.error('Failed to request cancelStudentSubscription:', error);
    }
  }

  const cancelSubscriptionDialog = async () => {
    setVisibleCancelDialog(true);
  }

  if (!user) {
    return <Navigate to="/login" />;
  } else {
    if (user?.role === 'parent') {
      return <Navigate to="/children" />;
    }
  }

  const isValidDate = (dateString: string): boolean => {
    const datePart = dateString.split('T')[0];
    return datePart !== "0001-01-01";
  };

  if (data && data?.status === 'pending' && lastMessage !== 'pending') {
    setLastMessage('pending');
    msgs.current?.clear();
    msgs.current?.show({
      severity: 'info',
      sticky: true,
      closable: false,
      detail: 'Eine E-Mail wurde an unser Verwaltungsteam gesendet. Sie werden in Kürze weitere Informationen erhalten.',
    });
  }

  const showActiveAlertMsg = () => {
    setLastMessage('approved');
    msgs.current?.clear();
    msgs.current?.show({
      severity: 'success',
      sticky: true,
      closable: false,
      detail: 'Dein Abonnement ist aktiv.',
    });
  }

  if (data && data?.status === 'approved' && lastMessage !== 'approved') {
    showActiveAlertMsg();
  }

  const refreshComponent = () => {
    queryClient.invalidateQueries(['myLastSub', token, userId]);
  }

  const handleSuccess = async (details: any) => {
    let purchaseUnit = null;
    if (details?.purchase_units?.length > 0) {
      purchaseUnit = details?.purchase_units[0];
    }
    // console.log("Payment successful:", details);
    toast.current?.show({ severity: 'success', summary: 'Successful', detail: `You have payed the ${purchaseUnit?.description} subscription`, life: 4000 });
    const payPalCallbackObj = {
      orderId: details?.id,
      currencyCode: purchaseUnit?.amount?.currency_code,
      amount: parseFloat(purchaseUnit?.amount?.value),
      subName: purchaseUnit?.description,
      userId: parseInt(purchaseUnit?.custom_id),
      feePercentage: "3.5%",
      payerName: details?.payer?.name?.given_name,
      payerSurname: details?.payer?.name?.surname,
      parentUserId: 0,
      payCreatedAt: details?.create_time,
    }
    try {
      await payPalCallbackAPI(token, payPalCallbackObj);
      queryClient.invalidateQueries(['myLastSub', token, userId]);
      msgs.current?.clear();
      showActiveAlertMsg();
    } catch (error) {
      toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong, try again!' });
      console.log('Failed to request payPalCallbackAPI:', error);
    }
  };

  const handleError = (error: any) => {
      console.error("Payment error:", error);
      toast.current?.show({ severity: 'error', summary: 'Error', detail: error.message });
  };

  return (
    <div className="subscriptions-container">
      {hasActiveSubscription && data?.status === 'pending' && (
        <div className='subscription-info-card'>
          <Button 
            icon="pi pi-refresh" 
            label="Refresh" 
            outlined
            className="p-button-secondary" 
            onClick={refreshComponent} 
          />
        </div>
      )}
      <Toast ref={toast} />
      <ConfirmDialog acceptLabel='Ja' rejectLabel='Nein' rejectClassName='p-button-secondary' acceptClassName="p-button-danger" visible={visibleCancelDialog} onHide={() => setVisibleCancelDialog(false)} message={"Nach der Kündigung müssen Sie ein neues Abonnement anfordern."} header="Bist du sicher?" icon="pi pi-exclamation-triangle" accept={acceptCancel} />

      <Messages className='subscription-info-card' ref={msgs} />

      {hasActiveSubscription ? (
        <Card className="subscription-info-card">
          <Fieldset legend="Aktuelles Abonnement">
            <p><strong>Plan:</strong> {data.name}</p>
            <p><strong>Startdatum: </strong> 
              {isValidDate(data.startDate) ? new Date(data.startDate).toLocaleDateString(): 'N/A'}
            </p>
            <p><strong>Ablaufdatum: </strong>
              {isValidDate(data.startDate) ? new Date(data.expirationDate).toLocaleDateString(): 'N/A'}
            </p>
            <p><strong>Betrag: </strong> CHF {data.payAmount}</p>
            <p><strong>Verbleibende Fragen: </strong> {data.credits}</p>
            <p><strong>Status: </strong>
              {data?.status === 'pending' && (
                <Tag style={{padding: '7px'}} className="mr-2" icon="pi pi-info-circle" severity="info" value="Ausstehend"></Tag>
              )}
              {data?.status === 'approved' && (
                <Tag style={{padding: '7px'}} className="mr-2" icon="pi pi-check" severity="success" value="Bestätigt"></Tag>
              )}
            </p>
          </Fieldset>
          {data?.status === 'approved' && (
            <Button onClick={cancelSubscriptionDialog} style={{ width: '100%', fontWeight: 'bold' }} label="Abonnement kündigen" className='p-button-danger mt-3' />
          )}
          <Divider></Divider>
          <small>Für Hilfe schreiben Sie bitte eine E-Mail an info@lern-buddy.com</small>
        </Card>
      ) : (
        <div className='subscriptions-cards-container mt-2'>
          <Card title="Standard" className="subscription-card">
            <h3 className='mt-0'>On Time - 1 Anfrage</h3>
            <div className="subscription-details">
              <h2>CHF 18.00</h2>
            </div>
            <div>
              <Button label="Bezahlung" icon="pi pi-credit-card" className="p-button-primary" onClick={() => setPaypalCheckoutStandardModalVisible(true)} />
              <PayPalModal
                  visible={paypalCheckoutStandardModalVisible}
                  onHide={() => setPaypalCheckoutStandardModalVisible(false)}
                  currency="CHF"
                  onSuccess={handleSuccess}
                  onError={handleError}
                  amount="18.00"
                  subName="Standard"
                  userId={userId}
              />
            </div>
          </Card>

          <Card title="Basic" className="subscription-card">
            <h3 className='mt-0'>Monatsabo - 10 Anfragen</h3>
            <div className="subscription-details">
              <h2>CHF 150.00 / Monat</h2>
            </div>
            <div>
              <Button label="Bezahlung" icon="pi pi-credit-card" className="p-button-primary" onClick={() => setPaypalCheckoutBasicModalVisible(true)} />
              <PayPalModal
                  visible={paypalCheckoutBasicVisible}
                  onHide={() => setPaypalCheckoutBasicModalVisible(false)}
                  currency="CHF"
                  onSuccess={handleSuccess}
                  onError={handleError}
                  amount="150.00"
                  subName="Basic"
                  userId={userId}
              />
            </div>
          </Card>

          <Card title="Pro" className="subscription-card">
            <h3 className='mt-0'>Jahresabo - 100 Anfragen</h3>
            <div className="subscription-details">
              <h2>CHF 1'200.00 / Jahr</h2>
            </div>
            <div>
              <Button label="Bezahlung" icon="pi pi-credit-card" className="p-button-primary" onClick={() => setPaypalCheckoutProModalVisible(true)} />
              <PayPalModal
                  visible={paypalCheckoutProVisible}
                  onHide={() => setPaypalCheckoutProModalVisible(false)}
                  currency="CHF"
                  onSuccess={handleSuccess}
                  onError={handleError}
                  amount="1200"
                  subName="Pro"
                  userId={userId}
              />
            </div>
          </Card>

          <Card title="Unlimited" className="subscription-card">
            <h3 className='mt-0'>Jahresabo - 300 Anfragen</h3>
            <div className="subscription-details">
              <h2>CHF 4'000.00 / Jahr</h2>
            </div>
            <div>
              <Button label="Bezahlung" icon="pi pi-credit-card" className="p-button-primary" onClick={() => setPaypalCheckoutUnlimitedModalVisible(true)} />
              <PayPalModal
                  visible={paypalCheckoutUnlimitedVisible}
                  onHide={() => setPaypalCheckoutUnlimitedModalVisible(false)}
                  currency="CHF"
                  onSuccess={handleSuccess}
                  onError={handleError}
                  amount="4000"
                  subName="Unlimited"
                  userId={userId}
              />
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default Subscriptions;
