import { useEffect, useState } from 'react';
import { Menubar } from 'primereact/menubar';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { MenuItem } from 'primereact/menuitem';
import { deleteFromLocalStorage } from '../services/ls';
import { useAuth } from '../contexts/AuthContext';

export default function NavBar() {
    const { user, logout } = useAuth();
    const [userData, setUserData] = useState<any>(null);
    const location = useLocation();

    useEffect(() => {
        setUserData(user);
    }, [user]);

    const handleLogout = async () => {
        deleteFromLocalStorage('userToken');
        deleteFromLocalStorage('userId');
        deleteFromLocalStorage('userData');
        logout();
        return <Navigate to="/login" />;
    }

    const isActive = (url: string) => location.pathname === url;

    const createTemplate = (url: string, label: string, icon: string, badge?: string, shortcut?: string) => (
        <Link to={url} className="flex align-items-center p-menuitem-link" data-p-focused={isActive(url)}>
            <i className={`pi ${icon} mr-2`} />
            <span className="mx-2">{label}</span>
            {badge && <Badge className="ml-auto" value={badge} />}
            {shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{shortcut}</span>}
        </Link>
    );

    let items: MenuItem[] = [
        // {
        //     template: createTemplate('/', 'Home', 'pi pi-home')
        // },
    ];

    if (userData?.role === 'student') {
        items.push(
            {
                template: createTemplate('/', 'Abonnemente', 'pi pi-credit-card')
            }
        )
    }

    if (userData?.role === 'parent') {
        items.push(
            {
                template: createTemplate('/children', 'Meine Kinder', 'pi pi-users')
            }
        )
    }

    const start = <a target='_blank' href="/"><img width={100} src="/logo-green.svg" alt="Learn Buddy Logo" className="logo mx-5" /></a>;
    let end = (
        <Button
            label="Ausloggen"
            icon="pi pi-sign-out"
            className="custom-button"
            onClick={handleLogout}
        />
    );

    if (!userData) {
        items = [];
    }

    return (
        <div className="card">
            <Menubar
                style={{ minHeight: '75px', maxWidth: '1200px', margin: '0 auto', border: 'none' }}
                model={items}
                start={start}
                end={userData ? end : null}
            />
        </div>
    );
}
