import React, { ReactNode } from 'react';
import './Layout.css';
import NavBar from '../../components/Navbar';

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div>
        <div className="menubar-wrapper">
            <NavBar />
        </div>
        <div className="layout-content">{children}</div>
    </div>
  );
};

export default Layout;
