export const storeToken = (value) => {
  try {
    localStorage.setItem('userToken', value);
  } catch (e) {
    console.log('storeToken.error', e);
  }
};

export const storeUserId = (value) => {
  try {
    localStorage.setItem('userId', value.toString());
  } catch (e) {
    console.log('storeUserId.error', e);
  }
};

export const storeUserData = (value) => {
  try {
    localStorage.setItem('userData', JSON.stringify(value));
  } catch (e) {
    console.log('storeUserData.error', e);
  }
};

export const retrieveToken = () => {
  try {
    const value = localStorage.getItem('userToken');
    if (value !== null) {
      return value;
    } else {
      return null;
    }
  } catch (e) {
    console.log('retrieveToken.error', e);
    return null;
  }
};

export const retrieveDecodedToken = () => {
  try {
    const value = localStorage.getItem('userToken');
    if (value !== null) {
      return value;
    } else {
      console.log('No token found');
      return null;
    }
  } catch (e) {
    console.log('retrieveDecodedToken.error', e);
    return null;
  }
};

export const retrieveUserId = () => {
  try {
    const value = localStorage.getItem('userId');
    if (value !== null) {
      return value;
    } else {
      console.log('No user id found');
      return null;
    }
  } catch (e) {
    console.log('retrieveUserId.error', e);
    return null;
  }
};

export const retrieveUserData = () => {
  try {
    const value = localStorage.getItem('userData');
    if (value !== null) {
      return JSON.parse(value);
    } else {
      return null;
    }
  } catch (e) {
    console.log('retrieveUserData.error', e);
    return null;
  }
};

export const deleteFromLocalStorage = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.log(`Error deleting item with key ${key}:`, e);
  }
};
