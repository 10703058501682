import { createContext, useContext, useState, useEffect, ReactNode, FC } from 'react';
import { retrieveToken, retrieveUserData } from '../services/ls';

interface AuthContextType {
  user: any | null;
  login: (userData: any) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  login: () => {},
  logout: () => {},
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<any | null>(null);
//   const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchToken = async () => {
      const token = await retrieveToken();
      const userData = await retrieveUserData();
      const parsedUserData = JSON.parse(userData);
      if (token && userData) {
        setUser(parsedUserData);
      }
    //   setLoading(false);
    };
    fetchToken();
  }, []);

  const login = (userData: any) => {
    setUser(userData);
  };

  const logout = () => {
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
