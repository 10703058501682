import config from '../config.js';
import axios from 'axios';

const BASE_URL = config.BASE_URL;

export const pendingOrActiveLastSubscriptionsByUserIdAPI = async (token, userId) => {
    let apiUrl = `${BASE_URL}/subscriptions/users/active-pending/${userId}`;
    try {
        const response = await axios.get(apiUrl, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        const errorMessage = error.response ? error.response.data.error : error.message;
        console.log('pendingOrActiveLastSubscriptionsByUserIdAPI.errorMessage', errorMessage)
        return null;
        // throw errorMessage;
    }
};

export const requestStudentSubscription = async (token, subscriptionType) => {
  let apiUrl = `${BASE_URL}/subscriptions`;
  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: subscriptionType }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log('Error requesting subscription:', error);
    throw error;
  }
};

export const cancelStudentSubscription = async (token, subId) => {
  let apiUrl = `${BASE_URL}/subscriptions/cancel/${subId}`;
  try {
    const response = await axios.put(apiUrl, {}, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        } });
    return response.data;
  } catch (error) {
      const errorMessage = error.response ? error.response.data.error : error.message;
      console.log('errorMessage', errorMessage)
      throw new Error(errorMessage);
  }
};

export const parentChildRequestSubscription = async (token, body) => {
  let apiUrl = `${BASE_URL}/subscriptions/child`;
  try {
    const response = await axios.post(apiUrl, body, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        } });
    return response.data;
  } catch (error) {
      const errorMessage = error.response ? error.response.data.error : error.message;
      console.log('Error requesting subscription:', error);
      throw new Error(errorMessage);
  }
};

export const payPalCallbackAPI = async (token, body) => {
  let apiUrl = `${BASE_URL}/subscriptions/paypal-callback`;
  try {
    const response = await axios.post(apiUrl, body, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        } });
    return response.data;
  } catch (error) {
      const errorMessage = error.response ? error.response.data.error : error.message;
      console.log('Error requesting payPalCallbackAPI:', error);
      throw new Error(errorMessage);
  }
};


