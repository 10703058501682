let config;

if (process.env.REACT_APP_ENV === 'production') {
  config = require('./config.production').default;
} else if (process.env.REACT_APP_ENV === 'test') {
  config = require('./config.test').default;
} else {
  config = require('./config.development').default;
}

export default config;