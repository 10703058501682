import config from '../config.js';
import axios from 'axios';

const BASE_URL = config.BASE_URL;

export const myProfileAPI = async (token) => {
    let apiIUrl = `${BASE_URL}/users/me`;
    try {
        const response = await axios.get(apiIUrl, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        const errorMessage = error.response ? error.response.data.error : error.message;
        throw new Error(errorMessage);
    }
};

export const signUpAPI = async (signUpObj) => {
    let apiUrl = `${BASE_URL}/users/signup`;
    try {
        const response = await axios.post(apiUrl, signUpObj, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.data;
    } catch (error) {
        const errorMessage = error.response ? error.response.data.error : error.message;
        console.log('errorMessage', errorMessage)
        throw new Error(errorMessage);
    }
};

export const profileUpdateAPI = async (token, profile) => {
    let apiUrl = `${BASE_URL}/users/update-profile`;
    try {
        const response = await axios.put(apiUrl, profile, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        const errorMessage = error.response ? error.response.data.error : error.message;
        console.log('errorMessage', errorMessage)
        throw new Error(errorMessage);
    }
};

export const userProfileByIdAPI = async (token, id) => {
    let apiIUrl = `${BASE_URL}/users/find/${id}`;
    try {
        const response = await axios.get(apiIUrl, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        const errorMessage = error.response ? error.response.data.error : error.message;
        console.log('errorMessage', errorMessage)
        throw new Error(errorMessage);
    }
};

export const childSignUpAPI = async (signUpObj) => {
    let apiUrl = `${BASE_URL}/users/signup/child`;
    try {
        const response = await axios.post(apiUrl, signUpObj, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.data;
    } catch (error) {
        const errorMessage = error.response ? error.response.data.error : error.message;
        console.log('errorMessage', errorMessage)
        throw new Error(errorMessage);
    }
};

export const parentAssociateStudentAPI = async (token, signUpObj) => {
    let apiUrl = `${BASE_URL}/users/parent-associate-student`;
    try {
        const response = await axios.post(apiUrl, signUpObj, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });
        return response.data;
    } catch (error) {
        const errorMessage = error.response ? error.response.data.error : error.message;
        console.log('errorMessage', errorMessage)
        throw new Error(errorMessage);
    }
};

export const updateChildProfileAPI = async (token, profile) => {
    let apiUrl = `${BASE_URL}/users/update/childrens/${profile?.id}`;
    try {
        const response = await axios.put(apiUrl, profile, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        const errorMessage = error.response ? error.response.data.error : error.message;
        console.log('errorMessage', errorMessage)
        throw new Error(errorMessage);
    }
};

export const registerUserDeviceAPI = async (token, userDeviceObj) => {
    let apiUrl = `${BASE_URL}/user-devices`;
    try {
        const response = await axios.post(apiUrl, userDeviceObj, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });
        return response.data;
    } catch (error) {
        const errorMessage = error.response ? error.response.data.error : error.message;
        console.log('errorMessage', errorMessage)
        throw new Error(errorMessage);
    }
};


export const deleteUserDeviceAPI = async (token, userDeviceObj) => {
    let apiUrl = `${BASE_URL}/user-devices`;
    try {
        const response = await axios.delete(apiUrl, {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            },
            data: userDeviceObj
        });
        return response.data;
    } catch (error) {
        const errorMessage = error.response ? error.response.data.error : error.message;
        console.log('deleteUserDeviceAPI.errorMessage', errorMessage)
        throw new Error(errorMessage);
    }
};

export const updateChildPasswordAPI = async (token, childId, newPassword, confirmNewPassword) => {
    let url = `${BASE_URL}/users/update/child-password/${childId}`;
    try {
        const response = await axios.put(url, { newPassword, confirmNewPassword }, {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        const errorMessage = error.response ? error.response.data.error : error.message;
        console.log('updateChildPasswordAPI.errorMessage', errorMessage)
        throw new Error(errorMessage);
    }
};

export const deleteAccountAPI = async token => {
    let apiUrl = `${BASE_URL}/users/delete-account`;
    try {
        const response = await axios.delete(apiUrl, {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        const errorMessage = error.response ? error.response.data.error : error.message;
        console.log('deleteAccountAPI.errorMessage', errorMessage)
        throw new Error(errorMessage);
    }
};